
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.header {
  @include Media.medium {
    @include Mixin.sticky-header(Color.$gainsboro, Color.$snow);
    margin-bottom: 0;
  }

  @media print {
    display: none;
  }
}

@include Media.lt-medium {
  /* stylelint-disable-next-line selector-no-qualifying-type */
  div.searchBar {
    @include Mixin.sticky-mask(Color.$snow, 16px);

    position: sticky;
    top: 16px;
    background-color: Color.$snow;
    z-index: 2;
  }
}

@include Media.medium {
  .header {
    top: 32px;

    &::before {
      height: 32px;
      top: -32px;
    }
  }
}

.footer {
  display: flex;
  width: 100%;
}

.footerGroup {
  flex-grow: 9999;

  @include Media.medium {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  > * + * {
    margin: 8px 0 0;

    @include Media.medium {
      margin: 0 0 0 16px;
    }
  }
}

.footerGroupHeader {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;

  > * + * {
    margin: 0 0 0 28px;

    @include Media.medium {
      margin: 0 0 0 16px;
    }
  }
}

.bulkButton {
  color: Color.$surfie;
  font-weight: bold;
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0;

  &:hover {
    color: Color.$surfie-hover;
  }

  &:active {
    color: Color.$surfie-hover;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 4px;
  }
}

.deselect {
  align-self: flex-start;

  @include Media.medium {
    align-self: center;
  }
}

.actionLink {
  align-self: flex-end;
  flex-shrink: 0;
  margin-top: 16px;

  @include Media.medium {
    .filterCount {
      display: none;
    }
  }

  @include Media.lt-medium {
    @include Mixin.sticky-mask(Color.$snow, 8px);

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: Color.$snow;
    z-index: 1;
    top: 56px;
    padding-bottom: 8px;
    border-bottom: 1px solid Color.$gainsboro;
    margin-bottom: 8px;
  }
}

.defaultSelected {
  display: none;
}

.filterBar {
  display: flex;
  flex-wrap: nowrap;

  > div:first-child,
  > div:nth-child(2) {
    flex-basis: calc(50% - 16px);
    max-width: calc(50% - 16px);
    flex-shrink: 1;
    flex-grow: 0;
    margin-right: 16px;
    min-width: 0;

    @include Media.lt-medium {
      max-width: 100%;
      margin-right: 0;
    }
  }
}

@include Media.lt-medium {
  .filterBar {
    display: block;

    > div:first-child,
    > div:nth-child(2) {
      margin-right: 0;
    }
  }
}

.customerFormBanner {
  margin: 16px 0;
}

// Table only
.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }
}

    