
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

@mixin show-title {
  display: initial;
}

.btn {
  align-items: center;
  background-color: transparent;
  border: 0 none;
  color: Color.$surfie;
  display: flex;
  font-weight: bold;
  margin: 0 0 0 24px;
  padding: 0;
  text-decoration-line: none;
  border-radius: 2px;
  outline-offset: 2px;

  .icon svg {
    height: 24px;
    width: 24px;
  }

  .title {
    display: none;
    margin: 0 0 0 8px;

    @include Media.medium {
      @include show-title;
    }
  }

  // The order of the "hover", "active", and "disabled" selectors is important
  // as each should override styles of the previous ones.

  &:hover {
    color: Color.$surfie-hover;
    cursor: pointer;
  }

  &:active {
    color: Color.$surfie-active;
  }

  &:disabled {
    color: Color.$slate;
    cursor: initial;
  }
}

.btn.inDropdown {
  margin: 0;
  padding: 16px;
  width: 100%;

  .title {
    @include Mixin.text-ellipsis;
    @include show-title;
  }
}

.dropdown {
  background: Color.$snow;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
  width: 240px;
}

.moreContainer {
  > .dropdown {
    visibility: hidden;
  }

  &.opened > .dropdown {
    visibility: visible;
  }
}

    