
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.scrollXArea {
  max-width: 100%;
  position: sticky;
  top: 0;
  bottom: 0;

  overflow-x: auto;
  overflow-y: hidden;

  // disabled navigation events on side scroll
  overscroll-behavior-x: contain;

  @media print {
    overflow: auto;
    position: relative;
    top: auto !important;
    bottom: auto !important;
  }
}

.contentContainer {
  overflow: hidden;
  position: sticky;
  left: 0;
  right: 0;

  @media print {
    overflow: auto;
    height: auto !important;
    position: relative;
    background-color: Color.$snow;
  }
}

    