
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.menuButton {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
}

.menuHeading {
  color: Color.$eclipse;
  padding: 10px 12px 2px;
}

.menuSeperator {
  height: 1px;
  background-color: Color.$gainsboro;
  margin: 4px 8px;
}

.menuItems {
  --anchor-gap: 4px;
  z-index: Var.$dropdown-zindex;
  background-color: Color.$snow;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  width: 200px;
  padding: 4px 0;
}

.menuSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menuItem {
  background-color: inherit;
  border: 0;
  display: flex;
  gap: 8px;
  padding: 10px 12px;
  color: Color.$surfie;
  font-weight: bold;
  width: 100%;

  &:hover {
    background-color: Color.$smoke;
    color: Color.$surfie-dark;
  }
}

    