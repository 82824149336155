
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.paymentAccount {
  display: flex;
  align-items: center;
  padding: 12px 8px;

  &:hover {
    background-color: Color.$cloud;
    border-radius: 8px;
  }

  @include Media.lt-medium {
    align-items: flex-start;
  }

  &.variant-alert {
    border: 1px solid fade-out(Color.$jaffa, 0.4);
    background: fade-out(Color.$jaffa, 0.9);
    border-radius: 4px;

    &:hover {
      background: fade-out(Color.$jaffa, 0.8);
    }
  }
}

.data {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include Media.lt-medium {
    flex-wrap: wrap;
  }
}

.expired {
  color: Color.$punch;

  svg {
    margin-right: 4px;
    vertical-align: text-bottom;
  }
}

.title {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  svg {
    color: Color.$jaffa;
  }
}

.logo {
  width: 48px;
  height: 32px;
  margin-right: 16px;
}

.badge {
  display: inline-flex;
}

.metaInfo {
  line-height: 1;
}

.actionMenu {
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;

  @include Media.lt-medium {
    flex-basis: 100%;
    margin-top: 8px;;
    flex-direction: column;
    align-items: normal;
  }
}

.externalLinkIcon {
  margin-left: 4px;
}

    