
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.loadingIndicator {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcomeMessage {
  white-space: pre-wrap;
}

.screen {
  padding: 0 16px;
  max-width: 960px;
  margin: 48px auto;

  h1 {
    font-weight: 600;
  }
}

.headerLogos {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 600;

  .companyLogo {
    flex-shrink: 0;
  }
}

.header {
  margin: 48px 0 24px;
  font-size: 32px;
  font-weight: 600;
}

.sections {
  margin-top: 48px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  @include Media.medium {
    display: flex;
  }
}

.section {
  padding: 24px;
  background-color: Color.$surfie-lightest;
  border-radius: 20px;
  height: 248px;
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: Color.$surfie-darker;

  .count {
    color: Color.$snow;
    border-radius: 50%;
    background-color: Color.$surfie;
    display: flex;
    width: 24px;
    height: 24px;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
}

.signIn {
  margin: 48px -16px 0;
  max-width: 560px;
  border-radius: 4px;

  @include Media.medium {
    margin: 48px auto 0;
    border: 1px solid Color.$gainsboro;
  }
}

.footer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}

    