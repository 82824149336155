
// Injected by 'magical-scss-loader'
@use "app/styles/color" as Color;
@use "app/styles/media" as Media;
@use "app/styles/var" as Var;
@use "app/styles/mixin" as Mixin;
// Injected by 'magical-scss-loader'

.container {
  @include Mixin.no-print();
  display: flex;
  justify-content: space-between;
}

.backButton {
  display: flex;
  align-self: flex-start;
  position: relative;
}

.actionButtons {
  display: flex;
  align-self: flex-end;
  position: relative;
  margin-left: auto;
}

    